import { createContext, useContext, useReducer } from "react";
import { getProjectGaps } from "../../actions/projects";
import { actions, transformGaps, transformGroups } from "./constants";
import { createGroup, deleteGroup, getGroups, updateGroup } from "../../actions/groupActions";
import { toVeracityCoordinates } from "../../utilities/geoUtilities";

const ProjectContext = createContext(null);
const ProjectDispatchContext = createContext();

function ProjectReducer(state, action) {
  switch (action.type) {
    case actions.SELECTED_PROJECT_CHANGED:
      action.dispatch({
        type: actions.FETCH_GAPS,
        dispatch: action.dispatch,
      });
      action.dispatch({
        type: actions.FETCH_GROUPS,
        dispatch: action.dispatch,
      });
      return {
        ...state,
        selectedProject: action.payload,
        gaps: [],
        groups: [],
        selectedGapId: null,
        selectedGroupId: null,
      };

    case actions.FETCH_GAPS:
      getProjectGaps(state.selectedProject.id).then((response) => {
        const gaps = transformGaps(response.data);

        action.dispatch({
          type: actions.GAPS_RECEIVED,
          payload: gaps,
        });
      });

      return {
        ...state,
      };

    case actions.GAPS_RECEIVED:
      return {
        ...state,
        gaps: action.payload,
      };

    case actions.GAP_SELECTED:
      return {
        ...state,
        selectedGapId: action.payload,
      };

    case actions.FETCH_GROUPS:
      getGroups(state.selectedProject.id).then((response) => {
        const groups = transformGroups(response.data);

        action.dispatch({
          type: actions.GROUPS_RECEIVED,
          payload: groups,
        });
      });

      return {
        ...state,
      };

    case actions.GROUPS_RECEIVED:
      return {
        ...state,
        groups: action.payload,
      };

    case actions.GROUP_SELECTED:
      return {
        ...state,
        selectedGroupId: action.payload,
      };

    case actions.GROUP_ADDED:
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };

    case actions.UPDATE_GROUP:
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.groupId === action.payload.groupId) {
            return action.payload;
          }
          return group;
        }),
      };

    case actions.SAVE_GROUP:
      if (!action.payload.groupId) {
        createGroup(state.selectedProject.id, {
          ...action.payload,
          coordinates: toVeracityCoordinates(action.payload.coordinates),
        })
          .then((response) => {
            action.payload.groupId = response;
            action.dispatch({
              type: actions.GROUP_SAVED,
              payload: action.payload,
            });
          });
      } else {
        updateGroup(state.selectedProject.id, {
          ...action.payload,
          coordinates: toVeracityCoordinates(action.payload.coordinates),
        })
          .then(() => {
            action.dispatch({
              type: actions.GROUP_SAVED,
              payload: action.payload,
            });
          });
      }
      return state;

    case actions.GROUP_SAVED:
      debugger;
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.groupId === action.payload.groupId) {
            return transformGroups([action.payload])[0];
          }
          return group;
        }),
        selectedGroupId: action.payload.groupId,
      };

    case actions.DELETE_GROUP:
      deleteGroup(state.selectedProject.id, action.payload)
        .then(() => {
          action.dispatch({
            type: actions.GROUP_DELETED,
            payload: action.payload,
          });
        });

      return state;

    case actions.GROUP_DELETED:
      return {
        ...state,
        selectedGroupId: null,
        groups: state.groups.filter((group) => group.groupId !== action.payload),
      };

    default:
      return state;
  }
}

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};

export const useProjectDispatch = () => {
  const context = useContext(ProjectDispatchContext);
  if (!context) {
    throw new Error("useProjectDispatch must be used within a ProjectProvider");
  }
  return context;
};

export const useSetSelectedProject = () => {
  const dispatch = useProjectDispatch();
  return (selectedProjectId) => {
    dispatch({
      type: actions.SELECTED_PROJECT_CHANGED,
      payload: selectedProjectId,
      dispatch,
    });
  };
};

export const useFetchGaps = () => {
  const dispatch = useProjectDispatch();
  return () => {
    dispatch({
      type: actions.FETCH_GAPS,
      dispatch,
    });
  };
};

export const useSetSelectedGap = () => {
  const dispatch = useProjectDispatch();
  return (gapId) => {
    dispatch({
      type: actions.GAP_SELECTED,
      payload: gapId,
    });
  };
};

export const useFetchGroups = () => {
  const dispatch = useProjectDispatch();
  return () => {
    dispatch({
      type: actions.FETCH_GROUPS,
      dispatch,
    });
  };
}

export const useSetSelectedGroup = () => {
  const dispatch = useProjectDispatch();
  return (groupId) => {
    dispatch({
      type: actions.GROUP_SELECTED,
      payload: groupId,
    });
  };
}

export const useAddGroup = () => {
  const dispatch = useProjectDispatch();
  return (group) => {
    dispatch({
      type: actions.GROUP_ADDED,
      payload: group,
    });
  };
}

export const useUpdateGroup = () => {
  const dispatch = useProjectDispatch();
  return (group) => {
    dispatch({
      type: actions.UPDATE_GROUP,
      payload: group,
    });
  };
}

export const useSaveGroup = () => {
  const dispatch = useProjectDispatch();
  return (group) => {
    dispatch({
      type: actions.SAVE_GROUP,
      payload: group,
      dispatch,
    });
  };
}

export const useDeleteGroup = () => {
  const dispatch = useProjectDispatch();
  return (groupId) => {
    dispatch({
      type: actions.DELETE_GROUP,
      payload: groupId,
      dispatch,
    });
  };
}

const ProjectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProjectReducer, {
    selectedProjectId: null,
    selectedGapId: null,
    selectedGroupId: null,
    selectedDetectionId: null,
    gaps: [],
    groups: [],
  });

  return (
    <ProjectContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectContext.Provider>
  );
};

export const ProjectConsumer = ProjectContext.Consumer;
export default ProjectProvider;
