import { CButton, CFormInput, CFormLabel } from "@coreui/react";
import React from "react";
import { useFetchHistory } from "../../contexts/SensorMock/SensorMockContext";

const HistoryControls = () => {
	const [frameName, setFrameName] = React.useState("");
	const [lookBackMinutes, setLookBackMinutes] = React.useState(0);

	const fetchHistory = useFetchHistory();

	const onHistorySearch = () => {
		const request = {
			name: frameName,
			lookBackMinutes,
		};

		fetchHistory(request);
	}

  return (
    <div className="history-controls">
      <CFormInput
				type="text"
				className="frame-name"
        value={frameName}
        placeholder="Frame name"
        onChange={(e) => setFrameName(e.target.value)}
			/>
			<CFormLabel>Minutes:</CFormLabel>
			<CFormInput
				type="number"
				className="look-back-minutes"
				value={lookBackMinutes}
				placeholder="Look back minutes"
				onChange={(e) => setLookBackMinutes(e.target.value)}
			/>
			<CButton
				onClick={onHistorySearch}
			>
				Load
			</CButton>
    </div>
  );
};

export default HistoryControls;
