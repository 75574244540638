import { CButton, CFormCheck, CFormInput, CFormLabel, CFormRange } from "@coreui/react";
import React from "react";
import "./Timeline.scss";
import { useAddFrameToTimeline, useImportTimeline, useSensorMock, useSwitchFrame, useUpdateFrameInfo } from "../../contexts/SensorMock/SensorMockContext";
import moment from "moment";
import JsonViewModal from "./JsonViewModal";
import generateJson from "../../utilities/sensorUtilities";
import { postTimeline } from "../../actions/sensorActions";

const Timeline = () => {
  const [viewJson, setViewJson] = React.useState(false);
  const [playToSelection, setPlayToSelection] = React.useState(false);
  const { currentFrame, currentFrameIndex, totalFrames, previousFrameDateTime, timeline } = useSensorMock();
  const addFrameToTimeline = useAddFrameToTimeline();
  const switchFrame = useSwitchFrame();
  const updateFrameInfo = useUpdateFrameInfo();
  const importTimeline = useImportTimeline();

  return (
    <div className="timeline">
      <div className="timeline-container">
        <CButton
          disabled={currentFrameIndex <= 1}
          onClick={() => switchFrame(currentFrameIndex - 1)}
          color="primary"
        >
          Back
        </CButton>
        <CFormLabel>Frame {currentFrameIndex} / {totalFrames}</CFormLabel>
        <CFormInput
          type="date"
          onChange={e => {
            const dateToSet = currentFrame.datetime || moment();
            const newDate = moment(e.target.value);
            dateToSet.set({
              year: newDate.year(),
              month: newDate.month(),
              date: newDate.date()
            });
            updateFrameInfo("datetime", dateToSet);
          }}
          value={currentFrame.datetime.format("YYYY-MM-DD")}
        />
        <CFormInput
          type="time"
          onChange={e => {
            const dateToSet = currentFrame.datetime || moment();
            const newDate = moment(`0001-01-01 ${e.target.value}`);
            dateToSet.hours(newDate.hours());
            dateToSet.minutes(newDate.minutes());
            updateFrameInfo("datetime", dateToSet);
          }}
          value={currentFrame.datetime.format("HH:mm")}
        />
        <CButton
          color="primary"
          onClick={() => {
            updateFrameInfo("datetime", moment());
          }}
        >
          Now
        </CButton>
        <CFormInput
          label="Mins from previous:"
          type="number"
          disabled={!previousFrameDateTime}
          onChange={e => {
            const dateToSet = previousFrameDateTime || moment();
            const newDate = moment(dateToSet).add(e.target.value, "minutes");
            updateFrameInfo("datetime", newDate);
          }}
          value={previousFrameDateTime ? moment(currentFrame.datetime).diff(previousFrameDateTime, "minutes") : ""}
        />
        <CButton
          color="primary"
          onClick={() => {
            if (currentFrameIndex === totalFrames) {
              addFrameToTimeline();
            } else {
              switchFrame(currentFrameIndex + 1);
            }
          }}
        >
          {currentFrameIndex === totalFrames ? 'Add' : 'Next'}
        </CButton>
      </div>
      <div className="timeline-slider">
        <CFormRange
          min={1}
          max={totalFrames}
          value={currentFrameIndex}
          onChange={(e) => {
            switchFrame(parseInt(e.target.value))
          }}
        />
      </div>
      <div className="timeline-controls">
        <CButton
          color="primary"
          onClick={() => setViewJson(true)}
        >
          View Timeline JSON
        </CButton>
        <CButton
          color="primary"

          onClick={() => {
            importTimeline([{ datetime: moment(), detections: [] }]);
          }}
        >
          Clear Timeline
        </CButton>
        <CButton
          color="primary"
          onClick={() => {
            //download file with json
            const element = document.createElement("a");
            const file = new Blob([JSON.stringify(timeline)], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = "timeline.json";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }}
        >
          Export Timeline
        </CButton>
        <CButton
          color="primary"
          onClick={() => {
            const element = document.createElement("input");
            element.type = "file";
            element.accept = ".json";
            element.onchange = (e) => {
              const file = e.target.files[0];
              if (!file) return;
              const reader = new FileReader();
              reader.onload = (e) => {
                const text = e.target.result;
                const data = JSON.parse(text);

                data.forEach(frame => {
                  frame.datetime = moment(frame.datetime);
                });

                importTimeline(data);
              };
              reader.readAsText(file);
            };
            element.click();
          }}
        >
          Import Timeline
        </CButton>

        <CFormCheck
          type="checkbox"
          label="Selection"
          checked={playToSelection}
          onChange={(e) => setPlayToSelection(e.target.checked)}
        />
        <CButton
          color="primary"
          onClick={() => {
            let newTimeline = [ ...timeline ];
            debugger;
            if (playToSelection) {
              newTimeline = timeline.slice(0, currentFrameIndex);
            }
            postTimeline(newTimeline).then(response => {
              console.log(response);
            });
          }}
        >
          Play Timeline
        </CButton>
      </div>

      <JsonViewModal
        visible={viewJson}
        onClose={() => setViewJson(false)}
        data={timeline.map(frame => ({
          datetime: frame.datetime.format("YYYY-MM-DD HH:mm"),
          frame: generateJson(frame.detections, frame.frame, frame.sensorName)
        }))}
      />
    </div>
  );
};

export default Timeline;
