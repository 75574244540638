import { createContext, useContext, useReducer } from "react";
import { actions, transformFrameObjects } from "./constants";
import {
  angleBetweenPoints,
  getCentroid,
  getOrderedPoints,
  getVehicle,
} from "../../utilities/geoUtilities";
import moment from "moment";
import { fetchFrameHistory } from "../../actions/sensorActions";

const SensorMockContext = createContext(null);
const SensorMockDispatchContext = createContext();

function SensorMockReducer(state, action) {
  switch (action.type) {

    case actions.DETECTION_ADDED:
      const currentTimeline = state.timeline[state.currentFrameIndex - 1];
      const { position, type, bindingGapId } = action.payload;
      const bindingGap = bindingGapId
        ? state.gaps.find((gap) => gap.gapId === bindingGapId)
        : null;

      let detection;

      if (!bindingGap) {
        detection = getVehicle(
          new Date().getTime(),
          position,
          0,
          type === "free" ? undefined : type
        );
      } else {
        const detectionsAlreadyInGap = currentTimeline.detections.filter(
          (detection) =>
            detection.boundGap && detection.boundGap.gapId === bindingGap.gapId
        );
        const lastOfDetections =
          detectionsAlreadyInGap.length > 0
            ? detectionsAlreadyInGap[detectionsAlreadyInGap.length - 1]
            : null;

        const gapAngle = lastOfDetections
          ? angleBetweenPoints(
              lastOfDetections.coordinates[0],
              lastOfDetections.coordinates[1]
            )
          : angleBetweenPoints(
              bindingGap.coordinates[0],
              bindingGap.coordinates[1]
            );
        const gapPosition = lastOfDetections
          ? lastOfDetections.coordinates[1]
          : bindingGap.coordinates[0];

        detection = getVehicle(
          new Date().getTime(),
          gapPosition,
          gapAngle,
          type === "free" ? undefined : type
        );
        detection.boundGap = bindingGap;
        detection.groupId = bindingGap.referenceData.split("#")[1];
      }

      detection.occupied = type !== "free";

      currentTimeline.detections.push(detection);
      return {
        ...state,
        timeline: [...state.timeline],
      };

    case actions.DETECTION_COORDINATES_UPDATED:
      const detectionToUpdate = state.timeline[state.currentFrameIndex - 1].detections.find(
        (detection) => detection.id === action.payload.id
      );
      detectionToUpdate.centroid = getCentroid(action.payload.coordinates);
      detectionToUpdate.coordinates = getOrderedPoints(
        action.payload.coordinates,
        detectionToUpdate.centroid
      );

      return {
        ...state,
        timeline: [...state.timeline],
      };

    case actions.DETECTION_INFO_UPDATED:
      const detectionToEdit = state.timeline[state.currentFrameIndex - 1].detections.find(
        (detection) => detection.id === action.payload.id
      );
      detectionToEdit[action.payload.property] = action.payload.value;

      return {
        ...state,
        timeline: [...state.timeline],
      };

    case actions.DETECTION_DELETED:
      const detections = state.timeline[state.currentFrameIndex - 1].detections;

      state.timeline[state.currentFrameIndex - 1].detections =
        detections.filter(detection => detection.id !== action.payload);
      return {
        ...state,
        timeline: [...state.timeline],
        selectedDetectionId: state.selectedDetectionId === action.payload ? null : state.selectedDetectionId,
      };

    case actions.DETECTIONS_CLEARED:
      state.timeline[state.currentFrameIndex - 1].detections = [];
      return {
        ...state,
        timeline: [...state.timeline],
      };

    case actions.DETECTION_SELECTED:
      return {
        ...state,
        selectedDetectionId: action.payload,
      };

    case actions.MAP_MODE_CHANGED:
      const isTurningOff = state.mapMode === action.payload;
      return {
        ...state,
        mapMode: isTurningOff ? null : action.payload,
      };

    case actions.FRAME_ADDED:
      return {
        ...state,
        timeline: [...state.timeline, {
          ...state.timeline[state.currentFrameIndex - 1],
          detections: state.timeline[state.currentFrameIndex - 1].detections.map(detection => ({ ...detection })),
        }],
        currentFrameIndex: state.timeline.length + 1,
        selectedDetectionId: null
      };

    case actions.FRAME_INFO_UPDATED:
      const frame = state.timeline[state.currentFrameIndex - 1];
      frame[action.payload.property] = action.payload.value;
      return {
        ...state,
        timeline: [...state.timeline],
      };

    case actions.FRAME_SWITCHED:
      return {
        ...state,
        currentFrameIndex: action.payload,
        selectedDetectionId: null
      };

    case actions.TIMELINE_IMPORTED:
      return {
        ...state,
        timeline: action.payload,
        currentFrameIndex: 1,
        selectedDetectionId: null
      };

    case actions.FETCH_HISTORY:
      const request = action.payload;
      fetchFrameHistory(request).then((response) => {
        if (response.data) {
          const timeline = response.data.map((frame) => ({
            datetime: moment(frame.createdOn),
            frame: frame.name,
            sensorName: frame.host,
            detections: transformFrameObjects(frame.objects),
          }));
          action.dispatch({
            type: actions.TIMELINE_IMPORTED,
            payload: timeline,
          });
        }
      });
      return { ...state };

    case actions.HISTORY_IMPORTED:
      return {
        ...state,
        timeline: action.payload,
        currentFrameIndex: 1,
        selectedDetectionId: null,
      };


    default:
      return state;
  }
}

const useSensorMock = () => {
  const context = useContext(SensorMockContext);
  if (context === undefined) {
    throw new Error("useSensorMock must be used within a SensorMockProvider");
  }
  return context;
};

const useSensorMockDispatch = () => {
  const context = useContext(SensorMockDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSensorMockDispatch must be used within a SensorMockProvider"
    );
  }
  return context;
};

const useAddDetection = () => {
  const dispatch = useSensorMockDispatch();
  return (position, type, bindingGapId) => {
    dispatch({
      type: actions.DETECTION_ADDED,
      payload: {
        position,
        type,
        bindingGapId,
      },
    });
  };
};

const useUpdateDetectionCoordinates = () => {
  const dispatch = useSensorMockDispatch();
  return (detectionId, coordinates) => {
    dispatch({
      type: actions.DETECTION_COORDINATES_UPDATED,
      payload: {
        id: detectionId,
        coordinates,
      },
    });
  };
};

const useUpdateDetectionInfo = () => {
  const dispatch = useSensorMockDispatch();
  return (detectionId, property, value) => {
    dispatch({
      type: actions.DETECTION_INFO_UPDATED,
      payload: {
        id: detectionId,
        property,
        value,
      },
    });
  };
};

const useDeleteDetection = () => {
  const dispatch = useSensorMockDispatch();
  return (detectionId) => {
    dispatch({
      type: actions.DETECTION_DELETED,
      payload: detectionId,
    });
  };
}

const useClearDetections = () => {
  const dispatch = useSensorMockDispatch();
  return () => {
    dispatch({
      type: actions.DETECTIONS_CLEARED,
    });
  };
}

const useSetSelectedDetection = () => {
  const dispatch = useSensorMockDispatch();
  return (detectionId) => {
    dispatch({
      type: actions.DETECTION_SELECTED,
      payload: detectionId,
    });
  };
}

const useSetMapMode = () => {
  const dispatch = useSensorMockDispatch();
  return (mode) => {
    dispatch({
      type: actions.MAP_MODE_CHANGED,
      payload: mode,
    });
  };
}

const useAddFrameToTimeline = () => {
  const dispatch = useSensorMockDispatch();
  return () => {
    dispatch({
      type: actions.FRAME_ADDED,
    });
  };
}

const useUpdateFrameInfo = () => {
  const dispatch = useSensorMockDispatch();
  return (property, value) => {
    dispatch({
      type: actions.FRAME_INFO_UPDATED,
      payload: {
        property,
        value,
      },
    });
  };
}

const useSwitchFrame = () => {
  const dispatch = useSensorMockDispatch();
  return (index) => {
    dispatch({
      type: actions.FRAME_SWITCHED,
      payload: index,
    });
  };
}

const useImportTimeline = () => {
  const dispatch = useSensorMockDispatch();
  return (timeline) => {
    dispatch({
      type: actions.TIMELINE_IMPORTED,
      payload: timeline,
    });
  };
}

const useFetchHistory = () => {
  const dispatch = useSensorMockDispatch();
  return (request) => {
    dispatch({
      type: actions.FETCH_HISTORY,
      payload: request,
      dispatch,
    });
  };
}

const SensorMockProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SensorMockReducer, {
    timeline: [
      {
        datetime: moment(),
        detections: [],
      },
    ],
    gaps: [],
    mapMode: null,
    currentFrameIndex: 1,
    selectedProjectId: null,
    selectedGapId: null,
    selectedDetectionId: null,
  });

  return (
    <SensorMockContext.Provider
      value={{
        ...state,
        defaultProjectName: "Neukirch International Airport",
        totalFrames: state.timeline.length,
        currentFrame: state.timeline[state.currentFrameIndex - 1],
        previousFrameDateTime: state.currentFrameIndex >= 2 ? state.timeline[state.currentFrameIndex - 2].datetime : null,
        detections: state.timeline[state.currentFrameIndex - 1].detections,
        selectedBoundGapId: getSelectedBoundGapId(state),
      }}
    >
      <SensorMockDispatchContext.Provider value={dispatch}>
        {children}
      </SensorMockDispatchContext.Provider>
    </SensorMockContext.Provider>
  );
};

const getSelectedBoundGapId = (state) => {
  if (!state.selectedDetectionId) {
    return null;
  }

  const selectedDetection = state.timeline[state.currentFrameIndex - 1].detections.find(detection => detection.id === state.selectedDetectionId);
  return selectedDetection.boundGap ? selectedDetection.boundGap.gapId : null;
};

export {
  useSensorMock,
  useSensorMockDispatch,
  useSetSelectedDetection,
  useAddDetection,
  useUpdateDetectionCoordinates,
  useUpdateDetectionInfo,
  useClearDetections,
  useDeleteDetection,
  useSetMapMode,
  useAddFrameToTimeline,
  useUpdateFrameInfo,
  useSwitchFrame,
  useImportTimeline,
  useFetchHistory,
};

export const SensorMockConsumer = SensorMockContext.Consumer;
export default SensorMockProvider;
